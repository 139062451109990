import React,{useEffect, useState} from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import {Switch} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";



const Navigationbar = () => {
  //MARK: React Hooks Code
  const [isActive, setActive] = useState(false)
  useEffect(() =>{
    window.addEventListener("scroll", changeNavbarBackGround)
  })

  //MARK: React Methods
  const changeNavbarBackGround = () => {
    if(window.scrollY >= 50){
      setActive(true)
    }
    else{
      setActive(false)
    }
  }

  //MARK: React Body 
 return (
    <>
    <Switch>
      <Navbar collapseOnSelect expand="lg" className={isActive? "active" :""} fixed="top">
        <Container>
          <Navbar.Brand href="#home" className={isActive? "brandActive" :"brand"}>&nbsp;JD</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto ">
              <Nav.Link className={isActive? "menuItemActive" :"menuItem"} href="#about">
                ABOUT
              </Nav.Link>
              <Nav.Link className={isActive? "menuItemActive" :"menuItem"} href="#skills">
                SKILLS
              </Nav.Link>
              <Nav.Link className={isActive? "menuItemActive" :"menuItem"} href="#experience">
                EXPERIENCE
              </Nav.Link>
              <Nav.Link className={isActive? "menuItemActive" :"menuItem"} href="#work">
                WORK
              </Nav.Link>
              <Nav.Link className={isActive? "menuItemActive" :"menuItem"} href="#contact">
                CONTACT
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </Switch>
    </>
  );
};

export default Navigationbar;
