import React,{useState, useEffect} from "react"
import { FaGithub, FaLinkedin, FaInstagram, FaTwitter} from 'react-icons/fa';
import { ImProfile } from 'react-icons/im';
import './hero.css'
import '../../w3css.css'
import { Container, Row, Col, Image} from "react-bootstrap"
import Fade from 'react-reveal/Fade'
import myImg from '../../jd.png'


const Hero = () => {
  const openProfilePage = (e) =>{
    switch (e) {
      case "Github":
          window.open('https://github.com/jdhalmahapatra', '_blank')
        break;
      case "LinkedIn":
          window.open('https://www.linkedin.com/in/jyotiradityadhalmahapatra', '_blank')
        break;
      case "Instagram":
          window.open('https://www.instagram.com/jyotiraditya12/', '_blank')
        break;
      case "Twitter":
          window.open('https://twitter.com/jyotiraditya12', '_blank')
        break;
      case "Resume":
          window.open('https://personal-jd.s3.ap-south-1.amazonaws.com/Jyotiraditya_Dhalmahapatra.pdf', '_blank')
        break;
      default:
        break;
    }
  }

  //To display the First Sub Heading
  const [text, setText] = useState("")
  const [fullText, setFullText] = useState(
    "A boy who aspired to be a cricketer but accidentally became a software engineer!"
  )
  const [index, setIndex] = useState(0)

  //After completion render the below stuffs conditionally
  const [isVisible, setVisibility] = useState(false);

  useEffect(()=>{
    if(index <fullText.length){
      setTimeout(()=>{
        setText((text + fullText[index]))
        setIndex(index+1)
      },1)
    }
    else{
      setVisibility(true)
    }
  }, [index])

  return (
    <section id="home" className="overlay">
      <Container>
        <Row className="topimgmargin">
          <Col>
            {isVisible?
              <Fade top> <Image src={myImg} className="profileImage" roundedCircle /></Fade>
              :
              <Image src="" />
            }
            
          </Col>
        </Row>
        <Row>
          <Col>
              <hr />
				      {/* <h3><span className="bold">{text}</span></h3> */}
				      {isVisible ?
                <Fade bottom><h1 className="heading" style={{color:"#eeeeee", fontFamily:"Montserrat"}}>
                  JYOTIRADITYA</h1></Fade>
                :
                <h1></h1>
              } 
              {isVisible?<Fade bottom><Col className="text-center">
                <FaGithub className="icon w3-animate-zoom" onClick={(e)=>openProfilePage('Github', e)}/>
                <FaLinkedin className="icon w3-animate-zoom" onClick={(e)=>openProfilePage('LinkedIn', e)}/>
                <FaInstagram className="icon w3-animate-zoom" onClick={(e)=>openProfilePage('Instagram', e)}/>
                <FaTwitter className="icon w3-animate-zoom" onClick={(e)=>openProfilePage('Twitter', e)}/>
                <ImProfile className="icon w3-animate-zoom" onClick={(e)=>openProfilePage('Resume', e)}/>
              </Col></Fade>:<></>}
				      {isVisible? 
                <Fade bottom><a href="#about" className="btn outline-light">EXPLORE</a></Fade>
                
                :
                <></>
              }
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
