import React from "react";
import { Container, Col, Row, Image, Card } from "react-bootstrap";
import {FaCode, FaCloud,FaGlobe, FaMobile, FaDatabase, FaHtml5, FaTools} from 'react-icons/fa'
import "./skills.css";
import Slide from 'react-reveal/Slide'
import skillsdata from './skillsdata.js'

function Skills(props) {
  return (
    <Container id="skills">
      <title>About</title>
      <Card className="card shadow1">
        <Row>
          <Col md={5} lg={5}></Col>
          <Col>
            <h2>My Skills</h2>
          </Col>
                {
                    skillsdata.map((content, index) => (
                        <Col md={6} lg={6} sm={4} className="mt-3 text-center">
                            <Image src={content.imgPath} fluid/>
                            <h4>{content.title}</h4>
                            <p className="skilldisplay">{content.description}</p>
                        </Col>
                    ))
                }
        </Row>
        {/* <Slide left>
            <Row>
                {
                    skillsdata.map((content, index) => (
                        <Col md={6} lg={6} sm={4} className="mt-3 text-center">
                            <Image src={content.imgPath} fluid/>
                            <h4>{content.title}</h4>
                            <p className="skilldisplay">{content.description}</p>
                        </Col>
                    ))
                }
            </Row>
        </Slide> */}
      </Card>
    </Container>
  );
}

export default Skills;
