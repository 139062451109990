import React from "react"
import {Container, Col, Row, Image, Card} from "react-bootstrap"
// import {FaCode, FaCloud,FaGlobe, FaMobile, FaDatabase, FaHtml5, FaTools} from 'react-icons/fa'
// import Fade from 'react-reveal/Fade'
import './about.css'
import myImage from '../../images/jd_about.jpg'
// import 'animate.css'


const subHeadingStyle ={
    color:'#333', 
    paddingTop:'10px', 
    fontStyle:'italic', 
    fontVariant:'small-caps', 
    fontWeight:'600', 
    fontFamily:'Roboto', 
    fontSize:'20px'
}
const mainParaStyle = {
    color: '#000',
    opacity: '0.9',
    fontSize: '14px',
    fontWeight:'350',
    fontFamily:'Roboto'
}
const About = () => {

    // const downloadResume = () =>{
    //     window.open('https://personal-jd.s3.ap-south-1.amazonaws.com/Jyotiraditya_Dhalmahapatra.pdf', "_blank")
    // }

    return ( 
        <Container id="about">
            <title>About</title>
            <Card className="card shadow1">
                <Row>
                <Col>
                    <h2>About Me</h2>
                    <p style={subHeadingStyle}>"A boy who aspired to be a cricketer but accidentally became a software engineer!"</p>
                    <p style={mainParaStyle}>Hi, My name is <strong>Jyotiraditya Dhalmahapatara</strong>. I am a full stack mobile and web developer. 
                        I strive to build mesmeric and beautiful web and mobile application through
                        carefully crafted code and user-centric design.</p>
                    <p style={mainParaStyle}>I am a forward-thinknig professional with over 9 years of experience
                    in wide range of technologies. I describe myself by the work I want to do. I choose to keep 
                    learning, continue challenging myself, and do interesting things that matters</p>
                    <p style={mainParaStyle}>I love writing code. Ever since I wrote my first code in C++(Not Hello, World! 😉) and able to produce the desired output, 
                    I fell in love with the idea of using software to solve practical problems. 
                    I have a strong belief in the power of programming to transform and improve the lives of people around the world.</p>
                    <p style={mainParaStyle}>I like to develop experties in many areas over the course of my life
                    and carrer. I am Extraverted, iNtuitive, Thinking, and Judging. In short, 
                    my personality type is <strong><a href="http://www.personalitypage.com/ENTJ.html" target="_blank">ENTJ.</a></strong></p>
                </Col>
                <Col md={5} lg={5}>
                    <Image src={myImage} fluid/>
                </Col>
                </Row>
            </Card>
            
            {/* <Row float="center">
                <Col md={12} className="text-center">
                    <h1 class="heading bold">ABOUT ME</h1>
				    <h2 class="subheading">FULL STACK WEB &amp; MOBILE APPLICATION DEVELOPER</h2>
                </Col>
                <Col md={4} lg={4} sm={4}>
                    <Fade top><div className="colbackground"><FaCloud className="fasize"/></div></Fade>
                    <Fade bottom><h3>Cloud Computing</h3></Fade>
					<Fade bottom><p>Certified AWS Solution Architect with over 4 years of successful 
                        experience in Cloud Architect and implementation. Good amount of experience 
                        in building high quality solution that is scalable and highly available.
                        </p></Fade>
                </Col>
                <Col md={4} lg={4} sm={4}>
                    <Fade top><div className="colbackground"><FaGlobe className="fasize"/></div></Fade>
                    <Fade bottom><h3>Web Development</h3></Fade>
					<Fade bottom><p>Creative Web Developer with 2+ years of experience in
                        Web App Developement that includes User Interfaces, testing, debugging,
                        and deployment. Clear understadning of modern technologies and best design-practices.
                        </p></Fade>
                </Col>
                <Col md={4} lg={4} sm={4}>
                    <Fade top><div className="colbackground"><FaMobile className="fasize"/></div></Fade>
                    <Fade bottom><h3>Mobile App Development</h3></Fade>
					<Fade bottom><p>Senior Mobile App Developer around 8 years of experience in Mobile Application Development
                        including Analysis, design, developement, testing, and deployment. Extensive experience in designing and 
                        implementing enterprise-based business application and product-based application. 
                        </p></Fade>
                </Col>
            </Row>
           <Fade bottom><Row float="center">
                <Col md={12} className="mt-5 text-center">
                    <h2 className="skillsHeading">MY SKILLS</h2>
                    <h5>I do all kinds of cool stuff</h5>
                </Col>
                <Col md={4} lg={4} sm={4} className="mt-3 text-center">
                    <div><FaCode className="siiconsize"/></div>
                    <h4>Back-End</h4>
                    <p className="skilldisplay">C#, .NET(Framework &amp; Core), MVC, EF, LINQ, Web API</p>
                </Col>
                <Col md={4} lg={4} sm={4} className="mt-3 text-center">
                    <div><FaHtml5 className="siiconsize"/></div>
                    <h4>Front-End</h4>
                    <p className="skilldisplay">HTML, JavaScript, React, jQuery, CSS, Bootstrap</p>
                </Col>
                <Col md={4} lg={4} sm={4} className="mt-3 text-center">
                    <div><FaMobile className="siiconsize"/></div>
                    <h4>Mobile App</h4>
                    <p className="skilldisplay">iOS, Objective-C, Swift, Cocoa Touch, Core Data, React Native</p>
                </Col>
                <Col md={4} lg={4} sm={4} className="mt-3 text-center">
                    <div><FaCloud className="siiconsize"/></div>
                    <h4>Cloud Engineering</h4>
                    <p className="skilldisplay">AWS &amp; Azure(Compute, Netwroking, Storage, Database)</p>
                </Col>
                <Col md={4} lg={4} sm={4} className="mt-3 text-center">
                    <div><FaDatabase className="siiconsize"/></div>
                    <h4>Database</h4>
                    <p className="skilldisplay">SQL Server, MongoDB, MySQL</p>
                </Col>
                <Col md={4} lg={4} sm={4} className="mt-3 text-center">
                    <div><FaTools className="siiconsize"/></div>
                    <h4>Other Software</h4>
                    <p className="skilldisplay">GitHub, Postman, Swagger, Trello, JIRA, BitBucket, Basecamp</p>
                </Col>
            </Row></Fade> */}
        </Container>
     );
}
 
export default About;