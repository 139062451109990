import React from 'react';
import {Container, Card, Row, Col} from 'react-bootstrap'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import './experience.css'
import data from './experiencedata.js'
import {MdWork} from "react-icons/md"

function Experience(props) {
    return (
        <Container id="experience">
            <title>Experience</title>
            <Card className="card shadow1">
                <Row>
                    <Col>
                        <h2>Experience</h2>
                    </Col>
                    <Col md={5} lg={5}></Col>
                    <VerticalTimeline className="custom-line mt-3">
                        {
                            data.map((content, index) =>(
                                <VerticalTimelineElement
                                    key={index}
                                    className="vertical-timeline-element--custom"
                                    contentStyle={{ background:content.cardBackground, color: content.cardText }}
                                    contentArrowStyle={{ borderRight: content.borderRight}}
                                    date={content.duration}
                                    dateClassName="elementclassname"
                                    iconStyle={{ background: content.radialColor, color: content.iconColor}}
                                    icon={<MdWork />}
                                >
                                <h3 className="vertical-timeline-element-title">{content.designation}</h3>
                                <h4 className="vertical-timeline-element-title">{content.organisation}</h4>
                                <h5 className="vertical-timeline-element-subtitle">{content.location}, {content.country}</h5>
                            </VerticalTimelineElement>
                            ))
                        }
                    </VerticalTimeline>
                </Row>
            </Card>
        </Container>
    );
}

export default Experience;