import React from 'react';

function Footer(props) {
    const footerstyle = {
        color: "#900c3f",
        margin: "20px",
        fontFamily: 'Montserrat',
        textAlign: "center"
      };
    return (
        <div>
           <footer style={footerstyle}>Made with ❤️ by Jyotiraditya</footer> 
        </div>
    );
}

export default Footer;