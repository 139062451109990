import React from 'react';
import { Container, Row, Col, Form, Button, Card} from 'react-bootstrap';
import {FaMobile, } from 'react-icons/fa'
import {MdEmail, MdLocationOn} from 'react-icons/md'

import './contact.css'
function Contact(props) {
    return (
        <Container id="contact" className="">
            <title>Contact</title>
            <Card className="card shadow1">
                <Row>
                    <Col>
                        <h2>Contact Me</h2>
                    </Col>
                    <Col md={5} lg={5}></Col> 
                </Row>
                <Row>
                    <Col md ={4} lg={4} className="text-center">
                        <Card className="card shadow1" style={{ width: '18rem' , height: '30rem'}}>
                            <Card.Body className="mt-4">
                                <Card.Title><FaMobile className="mobileicon"/></Card.Title>
                                <Card.Text>+91 - (966)365-9623</Card.Text>
                                <Card.Title><MdEmail className="mailIcon"/></Card.Title>
                                <Card.Text>jyotiraditya12@gmail.com</Card.Text>
                                <Card.Title><MdLocationOn className="locationIcon"/></Card.Title>
                                <Card.Text>Pune, India</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md= {8} lg={8}>
                        <Form name="contact" method="POST" className="mt-4" style={{padding:"38px"}}>
                        <input type="hidden" name="form-name" value="contact" />
                            <Row>
                                <Col md={12} lg={12} sm={12}>
                                    <Form.Group className="input" controlId="formNameGrid">
                                        <Form.Control className="inputHeight" type="name" name="name" placeholder="Name" />
                                    </Form.Group>
                                    <Form.Group className="input" controlId="formEmailGrid">
                                        <Form.Control className="inputHeight" type="email" name="email" placeholder="Email" />
                                    </Form.Group>
                                    <Form.Group className="input" controlId="formPhoneGrid">
                                        <Form.Control className="inputHeight" type="phone" name="phone" placeholder="Phone" />
                                    </Form.Group>
                                    <Form.Group className="message" controlId="form.message">
                                        <Form.Control as="textarea" name="message" rows={7} placeholder="Message"/>
                                    </Form.Group>
                                    <Button className="sendemail mt-4" variant="light" type="submit">SEND EMAIL</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </Container>
    );
}

export default Contact;