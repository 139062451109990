import React from "react";
import { Modal, Button, Row, Col, Image, Carousel } from "react-bootstrap";
import HorizontalSlider from "react-horizontal-slider";
import "./MyVerticallyCenteredModal.css";

function MyVerticallyCenteredModal(props) {
    debugger;
  let projectName = props.data.projectName;
  let screenShots = props.data.projectScreens;
  let applicationType = props.data.projectCategory;

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modalTitle"
          >
            {projectName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.data.projectDescription}</p>
          <Row>
            {applicationType === "Web Application" ? (
              <Carousel>
                <Carousel.Item>
                  <Image src={screenShots[0].screenUrl} fluid/>
                </Carousel.Item>
                <Carousel.Item>
                    <Image src={screenShots[1].screenUrl} fluid/>
                </Carousel.Item>
              </Carousel>
            ) : (
              screenShots.map((content, index) => (
                <Col md={4} lg={4}>
                  <Image
                    src={content.screenUrl}
                    style={{
                      width: "220px",
                      height: "476px",
                      marginLeft: "7px",
                    }}
                  />
                </Col>
              ))
            )}
          </Row>
          <p className="mt-4">Design Credit:- {props.data.designCredit}</p>
          <p> Application Type:- {props.data.projectCategory}</p>
          {props.data.accesibility ? (
            <p>
              Accessibility:-{" "}
              <a className= "anchorTag" href={props.data.URL} target="_blacnk">
                {props.data.URL}
              </a>
            </p>
          ) : (
            <p>Accessibility:- {props.data.alternativeAccess}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="closeButton"
            onClick={props.onHide}
            variant="light"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyVerticallyCenteredModal;
