const data = [
    {
        "organisation": "AArete Technology Service",
        "designation": "Technical Lead",
        "location":"Pune",
        "country": "India",
        "duration":"2019-Present",
        "radialColor": "#f85700",
        "cardBackground" :"#D5D8DE",
        "cardText": "#000",
        "borderRight": "7px solid  #D5D8DE",
        "iconColor": "#fff"
        
    },
    {
        "organisation": "AArete Technology Service",
        "designation":"Consultant",
        "location":"Pune",
        "country": "India",
        "duration":"2016-2019",
        "radialColor": "#f85700",
        "cardBackground" :"#D5D8DE",
        "cardText": "#000",
        "borderRight": "7px solid  #D5D8DE",
        "iconColor": "#fff"
    },
    {
        "organisation": "Boston Technology Corporation",
        "designation":"Senior Software Engineer",
        "location":"Bengaluru",
        "country": "India",
        "duration":"2015-2016",
        "radialColor": "#004A9F",
        "cardBackground" :"#D5D8DE",
        "cardText": "#000",
        "borderRight": "7px solid  #D5D8DE",
        "iconColor": "#fff"
    },
    {
        "organisation": "Pronto Software Solutions",
        "designation":"Software Engineer",
        "location":"Bengaluru",
        "country": "India",
        "duration":"2012-2015",
        "radialColor": "#00C4DA",
        "cardBackground" :"#D5D8DE",
        "cardText": "#000",
        "borderRight": "7px solid  #D5D8DE",
        "iconColor": "#fff"
    }
]
export default data;

