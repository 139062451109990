import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import Navigationbar from './components/navbar/Navigationbar';
import Hero from './components/landingpage/Hero';
import About from './pages/about/About';
import Footer from '../src/components/footer/Footer'
import Contact from './pages/contact/Contact';
import Experience from './pages/experience/Experience';
import Skills from './pages/skills/Skills';
import Work from './pages/work/Work';


ReactDOM.render(
  <React.StrictMode>
    <div>
      <Router><Navigationbar/></Router>
      <Hero/>
      <About/>
      <Skills/>
      <Experience/>
      <Work/>
      <Contact/>
      <Footer />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);


