const workdata = [
    {
        "projectName" : "Portfolio",
        "projectCategory" : "Web Application",
        "projectCode": 1,
        "projectThumbnail":"https://drive.google.com/uc?id=1dw6xTkjm61SnyF-_g8hDku3bCIBa6w03",
        "projectDescription":"This is my personal Protfolio Project. I have made this with Love",
        "techStacks" : "React, NPM, Bebel, BootStrap",
        "accesibility": true,
        "responsive": "Yes",
        "projectScreens":[
            {
                "screenUrl": "https://drive.google.com/uc?id=1unIhHbnRiqPHcambNZdn8exLrpDzjI90"
            },
            {
                "screenUrl":"https://drive.google.com/uc?id=1U9lMW5T3Nfhmf6XMz0Is7gFQP-OCFx9D"
            }
        ],
        "URL": "https://imjyotiraditya.dev",
        "alternativeAccess" : "",
        "designCredit": "Jyotiraditya"
    },
    {
        "projectName" : "Fructus",
        "projectCategory" : "Mobile Application",
        "projectCode": 2,
        "projectThumbnail":"https://drive.google.com/uc?id=1qJLICbM5TlaIkhXuW40wk3yDNLG6fY6S",
        "projectDescription":"It is a fruit encyclopedia applcation. You will get to know lots of unknown facts about Fruits. Also, the nutritional value each fruit contains. It is an iOS application. My First SwiftUI application.",
        "techStacks" : "Swift, SwiftUI 2.0, ,",
        "accesibility": false,
        "responsive": "False",
        "projectScreens":[
            {
                "screenUrl": "https://drive.google.com/uc?id=1nimmNksN6QePC2QMt740NCIVjQFJ64y8"
            },
            {
                "screenUrl":"https://drive.google.com/uc?id=1Morf03WDoXTR3skttjvlIrPYYy0TRcyQ"
            },
            {
                "screenUrl":"https://drive.google.com/uc?id=1kf9h5ZwZT9pEgexdKfLVyEai3yHASZuE"
            }
        ],
        "URL": "",
        "alternativeAccess" : "Please contact to me for more details.",
        "designCredit": "Robert Petras"
    },
    {
        "projectName" : "Animal Kingdom",
        "projectCategory" : "Mobile Application",
        "projectCode": 2,
        "projectThumbnail":"https://drive.google.com/uc?id=130H-pnA6NZWswB31maF2Bdslk9l5npKC",
        "projectDescription":"It is an Animal Kingdom application. You will get to know lots of unknown facts about various animals in the world. Very Nice and intuitive layout with HD images and videos of animals. Also, this application has a feature where you can transfer the iPad applcation to Mac using Mac Catalyst.",
        "techStacks" : "Swift, SwiftUI 2.0, Apple Map, JSON, MacCatalyst",
        "accesibility": false,
        "responsive": "False",
        "projectScreens":[
            {
                "screenUrl": "https://drive.google.com/uc?id=1L9SmlHCaCsD4kTszSuNUU8EWpjkQpiBO"
            },
            {
                "screenUrl":"https://drive.google.com/uc?id=1KuQbfvWYf_Euc5olmmwCVBhzOdfJfOaT"
            },
            {
                "screenUrl":"https://drive.google.com/uc?id=1VyWk3KmYLM-STpEArg25R9jbQqj9WrBx"
            }
        ],
        "URL": "",
        "alternativeAccess" : "Please contact to me for more details.",
        "designCredit": "Robert Petras"
    }

]
export default workdata;