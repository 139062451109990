const skilldata = [
    {
        "title":"Microsoft Stacks",
        "description":"C#, .NET(Framework & Core), MVC, EF, LINQ, Web API",
        "imgPath": "https://personal-jd.s3.ap-south-1.amazonaws.com/microsoft.png"
    },
    {
        "title":"Web Development",
        "description":"HTML, Javascript, React, jQuery, CSS, Bootstrap",
        "imgPath": "https://personal-jd.s3.ap-south-1.amazonaws.com/html.png"
    },
    {
        "title":"Mobile Application",
        "description":"iOS, Swift, SwiftUI, UIKit, Objective-C, React Native",
        "imgPath": "https://personal-jd.s3.ap-south-1.amazonaws.com/mobile-development.png"
    },
    {
        "title":"Database",
        "description":"SQL Server, MySQL, MongoDB",
        "imgPath": "https://personal-jd.s3.ap-south-1.amazonaws.com/databases.png"
    },
    {
        "title":"Cloud Engineering",
        "description":"AWS & Azure(Compute, Netwroking, Storage, Database)",
        "imgPath": "https://personal-jd.s3.ap-south-1.amazonaws.com/cloud.png"
    },
    {
        "title":"Other Software",
        "description":"Github, GitLab, Jenkins, Postman, Swagger, Trello, JIRA, BitBucket",
        "imgPath": "https://personal-jd.s3.ap-south-1.amazonaws.com/software-2.png"
    }
]
export default skilldata;