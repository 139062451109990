import React,{useState} from "react";
import { Container, Card, Row, Col, Image} from "react-bootstrap";
import MyVerticallyCenteredModal from "../../components/Modal/MyVerticallyCenteredModal";
import "./work.css";
import workData from "./workdata.js";



function Work(props) {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({
    projectName:"",
    projectScreens:[]
  });
  //MARK: User Defined Methods
  const openProjectModal = (e) =>{
    setModalShow(true)
    setModalData(e)
    //console.log("I landed here")
    //console.log(e)
  } 

  //MARK: BODY 
  return (
    <Container id="work">
      <title>Work</title>
      <Card className="card shadow1">
        <Row>
          <Col md={5} lg={5}></Col>
          <Col>
            <h2>Work Portfolio</h2>
          </Col>
        </Row>
        <Row className="mt-3">
          {
                workData.map((content, index) => (
                    <Col md={3} lg={3}>
                    <div className="work-box work-box-shadow">
                        <a href="#work" data-lightbox="gallery-mf" style={{textDecoration:"none"}} onClick={(e) => openProjectModal(content)}>
                        <div class="work-img">
                            <Image src={content.projectThumbnail} fluid/>
                        </div>
                            <Row className="p-2 work-content">
                            <Col sm={12}>
                                <h3 class="w-title">{content.projectName}</h3>
                                <div class="w-more">
                                <span class="w-ctegory">{content.projectCategory}</span>
                                </div>
                            </Col>
                            </Row>
                        </a>
                    </div>
                    </Col>
                ))
          }
        </Row>
      </Card>
        <MyVerticallyCenteredModal data = {modalData} show={modalShow} onHide={() => setModalShow(false)}
        backdrop='static'
        />
    </Container>
  );
}

export default Work;
